import React, { useEffect, useState } from 'react'
import Profileheader from './ProfileHeader'
import './profile.css'
import { Card, Col, Row } from 'react-bootstrap'
import { Box, Container, TextField, Select, MenuItem, FormControl, Button, Typography, Divider, FormHelperText } from '@mui/material'
import PersonIcon from '@mui/icons-material/Person';
import { SvgIcon } from '@mui/material';
import MailIcon from '@mui/icons-material/Mail';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { API_BASE_URL } from '../../../utility/apiConstants'
import SideNav from '../../../SideNav'
import MainFooter from '../../footer/MainFooter'
import { countrylist } from '../../../services/countryList';
import { authPost, getAuth, getReq, post } from '../../../utility/apiConfig'
import dayjs from 'dayjs';
import Autocomplete from '@mui/material/Autocomplete';
const Profile = () => {
    const [passportIssue, setPassportIssue] = useState();
    const [passportExpiry, setPassportExpiry] = useState();
    const [dateOfBith, setDateOfBith] = useState();
    const [states, setStates] = useState([]);
    const [loading, setLoading] = useState(false);
    const [cities, setCities] = useState([]);
    const [docFile, setDocFile] = useState([]);
    const [userDetails, setUserDetails] = useState({
        title: "", firstName: "", middleName: "", lastName: "",
        email: "", countryCode: "", state: "", phone: "",
        designation: "", city: "", country: "", countryOrigin: "",
        numOfPax: "", selectedDocument: "", userId: ""
    });
    const [userDetailsErr, setUserDetailsErr] = useState({
        title: null, firstName: null, middleName: null, lastName: null,
        email: null, countryCode: null, phone: null,
        designation: null, city: null, country: null, state: null,
        numOfPax: null
    });
    const [passportDetails, setPassportDetails] = useState({
        pancard: "", passport: "", country: "", countryOrigin: "", nationality: "",
    });

    function createData(id, name, calories, fat, carbs, protein, isConnected) {
        return { id, name, calories, fat, carbs, protein, isConnected };
    }

    const handleChange = (e) => {
        // setAge(event.target.value);
        setUserDetails({ ...userDetails, [e.target.name]: e.target.value });
        setUserDetailsErr({ ...userDetailsErr, [e.target.name]: null });
    }

    useEffect(() => {
        let scripts = 'https://unpkg.com/libphonenumber-js/bundle/libphonenumber-js.min.js'
        var script = document.createElement("script");
        script.src = scripts
        script.async = true;
        document.body.appendChild(script);
    }, [])

    useEffect(() => {
        getStateList();
        getCurrentUserDetails()
    }, [])

    const getCurrentUserDetails = () => {
        getAuth(API_BASE_URL + 'user/account', null)
            .then((response) => {
                let user_data = response.data.data.form_data;
                setUserDetails({
                    ...userDetails,
                    title: user_data.title,
                    firstName: user_data.first_name,
                    middleName: user_data.middle_name,
                    lastName: user_data.last_name,
                    email: user_data.email,
                    countryCode: user_data.country_code_value,
                    state: user_data.state,
                    phone: user_data.phone,
                    designation: user_data.designation,
                    city: user_data.city,
                    country: user_data.country,
                    countryOrigin: user_data.country_code,
                    numOfPax: user_data.no_of_traveler,
                    userId: user_data.user_id
                })
                setPassportDetails({ ...passportDetails, country: user_data.country, countryOrigin: user_data.country_code })
                getCityList(user_data.country_code);
                if (response.data.data?.pax_travel_info?.length > 0) {
                    let user_Travel_details = response.data.data?.pax_travel_info[0];
                    setPassportDetails({
                        ...passportDetails,
                        pancard: user_Travel_details.pan,
                        passport: user_Travel_details.passport_no,
                        country: countrylist.filter(x => x.origin === user_Travel_details.passport_issuing_country)[0]?.name,
                        countryOrigin: user_Travel_details.passport_issuing_country,
                        nationality: user_Travel_details.nationality,
                    })

                    setPassportIssue(formatDate2(user_Travel_details.passport_date_of_issue));
                    setPassportExpiry(formatDate2(user_Travel_details.passport_date_of_expiry));
                    setDateOfBith(formatDate2(user_Travel_details.dob));
                }
                if (response.data.data?.travel_docs?.length > 0) {
                    setDocFile(response.data.data?.travel_docs);
                }
            }).catch((error) => {
               // setStates([])
            })
    }
    function formatDate2(date) {
        let dd = date.split("-")[2];
        let mm = Number(date.split("-")[1]) - 1;
        let yy = date.split("-")[0];
        let newDate = dayjs().date(dd).month(mm).year(yy);
        return newDate;
    }
    const getStateList = () => {
        getReq(API_BASE_URL + 'data/india_state', null)
            .then((response) => {
                setStates(response.data.data.data);
                let st = response.data.data.data;
                st.find((state) => {
                    if (state.origin === userDetails.state) {
                        setUserDetails({ ...userDetails, state: state });
                        setUserDetailsErr({ ...userDetailsErr, state: null });
                    }
                })
            }).catch((error) => {
                setStates([])
            })
    }

    const getCityList = (countryId) => {
        try {
            getReq(API_BASE_URL + 'data/get_cities/' + countryId, null)
                .then((response) => {
                    setCities(response.data.data.data);
                }).catch((error) => {
                    setCities([])
                })
        } catch (error) {

        }
    }

    const handlePhoneChange = (e) => {
        setUserDetailsErr({ ...userDetailsErr, phone: null });
        const onlyNums = e.target.value.replace(/[^0-9]/g, '');
        if (onlyNums.length < 10) {
            setUserDetails({ ...userDetails, phone: onlyNums })
        } else if (onlyNums.length === 10) {
            const number = onlyNums;
            setUserDetails({ ...userDetails, phone: number })
        }
    }

    const handleTitleChange = (e) => {
        setUserDetails({ ...userDetails, title: e.target.value });
        setUserDetailsErr({ ...userDetailsErr, title: null });
    };

    const handleUpdate = () => {
        setLoading(true);
        const { userId, title, firstName, middleName, lastName, email, countryCode, state, phone, designation, city, country, countryOrigin, numOfPax } = userDetails
        let dataToSend = {
            user_id: userId,
            title: title,
            first_name: firstName,
            middle_name: middleName,
            last_name: lastName,
            // email: email,
            state: state,
            phone: phone,
            designation: designation,
            city: city,
            country: countryOrigin,
            no_of_traveler: numOfPax
        }

        try {
            authPost(API_BASE_URL + 'user/update_account', dataToSend)
                .then((response) => {
                    if (response.data.status) {
                        alert(response.data.message)
                        setLoading(false);
                        window.location.reload();
                    } else {
                        alert(response.data.message)
                        setLoading(false);
                    }
                })
                .catch((error) => {
                    alert('Somthing went wrong please try again')
                    setLoading(false);
                })
        } catch (error) {

        }
    }

    function formatDate(date) {
        let d = new Date(date);
        let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
        let mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d);
        let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
        let newDate = `${da}-${mo}-${ye}`
        return newDate;
    }

    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <SideNav header={"Profile"} />
                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                    <Container>
                        <Card className='px-4 py-2 mt-3'>
                            <Row className='mb-4'>
                                <Col xs={12} sm={12} md={12}>
                                    <Box className="imguserprofile">
                                        <div className="img-ico"><PersonIcon /></div>
                                        <div className="tltnameico">Hi, {userDetails.firstName + " " + userDetails.middleName + " " + userDetails.lastName}</div>
                                    </Box>
                                </Col>
                            </Row>
                            <Row className=' mb-3'>
                                <h6 className='bg-light-red px-3 py-2' >User Details</h6>
                                <Divider />
                            </Row>
                                <Row >
                                    <Col xs={12} sm={12} md={3} >
                                        <Box className="setBox ">
                                            <div className="userbox">
                                                <div className="user-ico"><SvgIcon><PersonIcon /></SvgIcon></div>
                                                <div className="user-details">
                                                    <div className="tlthedae">Title <span className='text-danger'>*</span></div>
                                                </div>
                                            </div>
                                            <FormControl fullWidth>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={userDetails.title}
                                                    placeholder="Title *"
                                                    onChange={(handleTitleChange)}
                                                    error={userDetailsErr.title}
                                                >
                                                    <MenuItem key={1} value={'mr'}>Mr</MenuItem>
                                                    <MenuItem key={2} value={'ms'}>Ms</MenuItem>
                                                    <MenuItem key={3} value={'mrs'}>Mrs</MenuItem>

                                                </Select>
                                                {/* {userDetailsErr.title !== null && (<FormHelperText style={{ color: "#d32f2f" }}>{userDetailsErr.title}</FormHelperText>)} */}
                                            </FormControl>
                                        </Box>
                                    </Col>

                                    <Col xs={12} sm={12} md={3} >
                                        <Box className="setBox ">
                                            <div className="userbox">
                                                <div className="user-ico"><SvgIcon><PersonIcon /></SvgIcon></div>
                                                <div className="user-details">
                                                    <div className="tlthedae">First Name <span className='text-danger'>*</span></div>
                                                </div>
                                            </div>
                                            <TextField
                                                autoComplete="given-name"
                                                name="firstName"
                                                required
                                                fullWidth
                                                id="firstName"
                                                placeholder="First Name"
                                                autoFocus
                                                value={userDetails.firstName}
                                                onChange={handleChange}
                                                error={userDetailsErr.firstName}
                                                helperText={userDetailsErr.firstName}
                                            />
                                        </Box>
                                    </Col>
                                    <Col xs={12} sm={12} md={3}>
                                        <Box className="setBox ">
                                            <div className="userbox">
                                                <div className="user-ico"><SvgIcon><PersonIcon /></SvgIcon></div>
                                                <div className="user-details">
                                                    <div className="tlthedae">Middle Name</div>
                                                </div>
                                            </div>
                                            <TextField
                                                autoComplete="given-name"
                                                name="middleName"
                                                required
                                                fullWidth
                                                id="middleName"
                                                placeholder="Middle Name"
                                                autoFocus
                                                value={userDetails.middleName}
                                                onChange={handleChange}
                                                error={userDetailsErr.middleName}
                                                helperText={userDetailsErr.middleName}
                                            />
                                        </Box>
                                    </Col>
                                    <Col xs={12} sm={12} md={3}>
                                        <Box className="setBox ">
                                            <div className="userbox">
                                                <div className="user-ico"><SvgIcon><PersonIcon /></SvgIcon></div>
                                                <div className="user-details">
                                                    <div className="tlthedae">Last Name <span className='text-danger'>*</span></div>
                                                </div>
                                            </div>
                                            <TextField
                                                autoComplete="given-name"
                                                name="lastName"
                                                required
                                                fullWidth
                                                id="lastName"
                                                placeholder="Last Name"
                                                autoFocus
                                                value={userDetails.lastName}
                                                onChange={handleChange}
                                                error={userDetailsErr.lastName}
                                                helperText={userDetailsErr.lastName}
                                            />
                                        </Box>
                                    </Col>
                                    <Col xs={12} sm={12} md={4}>
                                        <Box className="setBox">
                                            <div className="userbox">
                                                <div className="user-ico"><SvgIcon><MailIcon /></SvgIcon></div>
                                                <div className="user-details">
                                                    <div className="tlthedae">Email ID <span className='text-danger'>*</span></div>
                                                </div>
                                            </div>
                                            <TextField
                                                required
                                                fullWidth
                                                id="email"
                                                placeholder="Email Address"
                                                name="email"
                                                inputProps={{ readOnly: true }}
                                                autoComplete="email"
                                                value={userDetails.email}
                                                onChange={handleChange}
                                                error={userDetailsErr.email}
                                                helperText={userDetailsErr.email}
                                            />
                                        </Box>
                                    </Col>
                                    <Col xs={12} sm={12} md={4}>
                                        <Box className="setBox">
                                            <div className="userbox">
                                                <div className="user-ico"><SvgIcon><LocationOnIcon /></SvgIcon></div>
                                                <div className="user-details">
                                                    <div className="tlthedae">Country <span className='text-danger'>*</span></div>
                                                </div>
                                            </div>
                                            {userDetails.country !== "" && (
                                                <FormControl fullWidth>
                                                    <Autocomplete
                                                        disablePortal
                                                        id="combo-box-demo"
                                                        options={countrylist}
                                                        getOptionLabel={(option) => option.name}
                                                        value={countrylist.filter(x => x.name === userDetails.country)[0]}
                                                        renderInput={(params) => <TextField {...params} error={userDetailsErr.country} />}
                                                        onChange={(event, newValue) => {
                                                            if (newValue !== null) {
                                                                setUserDetails({ ...userDetails, country: newValue.name, countryCode: newValue.country_code, countryOrigin: newValue.origin });
                                                                setUserDetailsErr({ ...userDetailsErr, country: null, countryCode: null });
                                                                getCityList(newValue.origin);
                                                            }
                                                        }}
                                                    />
                                                </FormControl>
                                            )}
                                        </Box>
                                    </Col>
                                    <Col xs={12} sm={12} md={4}>
                                        <Box className="setBox">
                                            <div className="userbox">
                                                <div className="user-ico"><SvgIcon><PhoneAndroidIcon /></SvgIcon></div>
                                                <div className="user-details">
                                                    <div className="tlthedae">Phone Number <span className='text-danger'>*</span></div>
                                                </div>
                                            </div>
                                            <Box className="d-flex w-100">
                                                <FormControl style={{ flexBasis: "40%" }}>
                                                    <TextField
                                                        required
                                                        fullWidth
                                                        id="countryCode"
                                                        placeholder="Country Code"
                                                        name="countryCode"
                                                        value={userDetails.countryCode}
                                                        inputProps={{ readOnly: true }}
                                                    />
                                                </FormControl>

                                                <TextField
                                                    required
                                                    fullWidth
                                                    id="conatct"
                                                    placeholder="Contact Number"
                                                    name="phone"
                                                    autoComplete="contactNumber"
                                                    value={userDetails.phone}
                                                    onChange={handlePhoneChange}
                                                    error={userDetailsErr.phone}
                                                    helperText={userDetailsErr.phone}
                                                />
                                            </Box>
                                        </Box>
                                    </Col>


                                    {((userDetails.country || userDetails) === "India" && userDetails.state !== "" && states.length > 0) && (
                                        <Col xs={12} sm={12} md={4}>
                                            <Box className="setBox">
                                                <div className="userbox">
                                                    <div className="user-ico"><SvgIcon><LocationOnIcon /></SvgIcon></div>
                                                    <div className="user-details">
                                                        <div className="tlthedae">State <span className='text-danger'>*</span></div>
                                                    </div>
                                                </div>
                                                <Autocomplete
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    options={states}
                                                    getOptionLabel={(option) => option.en_name}
                                                    value={states?.filter(x => x.origin === userDetails.state)[0]}
                                                    renderInput={(params) => <TextField {...params} error={userDetailsErr.state} />}
                                                    onChange={(event, newValue) => {
                                                        if (newValue !== null) {
                                                            setUserDetails({ ...userDetails, state: newValue.origin });
                                                            setUserDetailsErr({ ...userDetailsErr, state: null });
                                                        }
                                                    }}
                                                />

                                            </Box>
                                        </Col>
                                    )}

                                    <Col xs={12} sm={12} md={4}>
                                        {(cities.length > 0 && userDetails.city !== "") && (
                                            <Box className="setBox">
                                                <div className="userbox">
                                                    <div className="user-ico"><SvgIcon><LocationOnIcon /></SvgIcon></div>
                                                    <div className="user-details">
                                                        <div className="tlthedae">City <span className='text-danger'>*</span></div>
                                                    </div>
                                                </div>
                                                <Autocomplete
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    options={cities}
                                                    getOptionLabel={(option) => option.destination}
                                                    value={cities.filter(x => x.origin === userDetails.city)[0]}
                                                    renderInput={(params) => <TextField {...params} error={userDetailsErr.city} />}
                                                    onChange={(event, newValue) => {
                                                        if (newValue !== null) {
                                                            setUserDetails({ ...userDetails, city: newValue.origin });
                                                            setUserDetailsErr({ ...userDetailsErr, city: null });
                                                        }
                                                    }}
                                                />
                                            </Box>
                                        )}
                                    </Col>

                                    <Col xs={12} sm={12} md={4}>
                                        <Box className="setBox">
                                            <div className="userbox">
                                                <div className="user-ico"><SvgIcon><CreditCardIcon /></SvgIcon></div>
                                                <div className="user-details">
                                                    <div className="tlthedae">Designation <span className='text-danger'>*</span></div>
                                                </div>
                                            </div>
                                            <TextField
                                                required
                                                fullWidth
                                                name="designation"
                                                placeholder="Designation"
                                                id="designation"
                                                autoComplete="designation"
                                                value={userDetails.designation}
                                                onChange={handleChange}
                                                error={userDetailsErr.designation}
                                                helperText={userDetailsErr.designation}
                                            />
                                        </Box>
                                    </Col>
                                    <Col xs={12} sm={12} md={4}>
                                        <Box className="setBox">
                                            <div className="userbox">
                                                <div className="user-ico"><SvgIcon><PersonIcon /></SvgIcon></div>
                                                <div className="user-details">
                                                    <div className="tlthedae">No. of Travelers <span className='text-danger'>*</span></div>
                                                </div>
                                            </div>
                                            <TextField
                                                required
                                                fullWidth
                                                name="numOfPax"
                                                placeholder="No. of Travelers"
                                                id="numOfPax"
                                                type="number"
                                                autoComplete="numOfPax"
                                                value={userDetails.numOfPax}
                                                onChange={handleChange}
                                                error={userDetailsErr.numOfPax}
                                                helperText={userDetailsErr.numOfPax}
                                            />
                                        </Box>
                                    </Col>
                                    <Col xs={12} md={12}>
                                        <Button onClick={() => handleUpdate()} variant='contained' size="medium" color='success' className='float-right'>Update</Button>
                                    </Col>
                                </Row>
                            </Card>
                    </Container>
                </Box>
            </Box>
            <MainFooter />
        </>
    )
}

export default Profile
